import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import AboutOne from "../component/about/AboutOne";
import BannerOne from "../component/banner/BannerOne";
import BannerThree from "../component/banner/BannerThree";
import BrandOne from "../component/brand/BrandOne";
import CounterUpOne from "../component/counterup/CounterUpOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import PricingOne from "../component/pricing/PricingOne";
import ProjectOne from "../component/project/ProjectOne";
import ServicePropOne from "../component/service/ServicePropOne";
import TestimonialOne from "../component/testimonial/TestimonialOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import Carousel from "react-material-ui-carousel";
import { Helmet } from "react-helmet-async";
import GoogleTagManager from "./GTM";

const Home = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>Best Software Company in Dubai | Origin Softwares</title>
          <link rel="canonical" href="./" />
          <meta
            name="description"
            content="Leading software development company in Dubai, offering innovative solutions for development for businesses looking to stay ahead of the competition."
          />
          <meta name="keywords" content="software company in Dubai" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <Carousel style={{ Height: "900px" }}>
            <BannerOne style={{ Height: "900px" }} />
            <BannerThree style={{ Height: "900px" }} />
            {/* <BannerOne /> */}
          </Carousel>

          <div className="section section-padding-2 bg-color-dark">
            <div className="container">
              <SectionTitle
                // subtitle="What We Can Do For You"
                title="Services we can help you with"
                description="An IT company providing IT services to businesses across various sectors
                promoted by a group of qualified and experienced professionals"
                textAlignment="heading-light-left"
                textColor=""
              />
              <div className="row">
                <ServicePropOne
                  colSize="col-xl-4 col-md-6"
                  serviceStyle=""
                  itemShow="6"
                />
              </div>
            </div>
            <ul className="list-unstyled shape-group-10">
              <li className="shape shape-1">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/line-9.png"}
                  alt="Circle"
                />
              </li>
              <li className="shape shape-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"}
                  alt="Circle"
                />
              </li>
              <li className="shape shape-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"}
                  alt="Circle"
                />
              </li>
            </ul>
          </div>
          <AboutOne />
          <ProjectOne />
          <CounterUpOne />
          <TestimonialOne />
          <div className="section bg-color-light section-padding">
            <div className="container">
              <div className="heading">The Latest from Origin Softwares</div>
              <SectionTitle
                title="MEDIA & AWARDS"
                textAlignment=""
                textColor=""
              />
              <PricingOne />
            </div>
            <ul className="list-unstyled shape-group-3">
              <li className="shape shape-1">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/line-1.png"}
                  alt="shape"
                />
              </li>
              <li className="shape shape-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"}
                  alt="shape"
                />
              </li>
            </ul>
          </div>
          <BrandOne />
          {/* <div className="section bg-color-light section-padding"></div> */}
          {/* <BlogOne /> */}
          <CtaLayoutOne />
          <FooterOne parentClass="" />
        </main>
      </div>
    </>
  );
};

export default Home;
